var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loaded
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3" },
          [_c("ct-centered-spinner")],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-card",
              [
                _c("b-card-text", [
                  _vm.lawOnCallTrialEligible
                    ? _c("div", [
                        _c("h3", { staticClass: "mt-0" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${_vm.product.name} - Free Trial (${_vm.product.trial_duration_days} Days)`
                              ) +
                              "\n            — " +
                              _vm._s(_vm._f("dollars")(_vm.product.price)) +
                              "\n          "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "After " +
                              _vm._s(_vm.product.trial_duration_days) +
                              " days, the price will be adjusted to $9 per month."
                          ),
                        ]),
                      ])
                    : _c("h3", { staticClass: "mt-0" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.product.name) +
                            " — " +
                            _vm._s(_vm._f("dollars")(_vm.product.price)) +
                            "\n        "
                        ),
                      ]),
                ]),
                _c("multi-select", {
                  attrs: {
                    value: _vm.selectedJurisdictions,
                    "track-by": "id",
                    multiple: true,
                    disabled: _vm.addingToCart,
                    label: "state_province_region",
                    placeholder:
                      "Select from the available jurisdictions for law on call service",
                    options: _vm.availableJurisdictions,
                  },
                  on: {
                    select: function ($event) {
                      return _vm.addServiceToCart($event.state_province_region)
                    },
                    remove: function ($event) {
                      _vm.removeFromCart({
                        id: _vm.cartItemForJurisdiction(
                          $event.state_province_region
                        ).id,
                      })
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function ({ option }) {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(option.jurisdiction) +
                              "\n        "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }